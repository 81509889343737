import actions from './actions'
import mutations from './mutations'

const state = {
  directorRules: null,
  directorRule: null,

  rulesMetadata: {},
  // rulesMetadata: {
  //   Filter: {
  //     Account: "",
  //     Lead: "",
  //     ...
  //   },
  //   MatchCriteria: {
  //     Account: "",
  //     Lead: "",
  //      ...
  //   },
  //   ...
  // },

  entities: [],
  segmentationReports: [],
  convertLeadStatuses: [],
  operators: [],
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
