import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  user: null,
  jwt: null,
  agentId: '737561b4-bc8f-11ea-bb1a-f40f241f8cc3',
  contextMessage: undefined,
  isSessionExpired: false,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
