import mutationTypes from './mutation-types'
import {
  getAddressVerifications,
  getAddressVerificationsStats,
} from '@/api/clean-verify/address-verifications'

export default {
  async getAddressVerificationsStats({ commit, dispatch }) {
    try {
      const { verificationsByEntityStats, verificationActionsStats } =
        await getAddressVerificationsStats()

      commit(mutationTypes.SET_ADDRESS_VERIFICATIONS_STATS, {
        verificationsByEntityStats,
        verificationActionsStats,
      })
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      console.error('AP:getResolvedStatsError', error)
    }
  },
  async getAddressVerifications({ commit, dispatch }) {
    try {
      const response = await getAddressVerifications()

      commit(mutationTypes.SET_ADDRESS_VERIFICATIONS, response)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      console.error('AP:getResolvedStatsError', error)
    }
  },
}
