import mutationTypes from './mutation-types'

export default {
  [mutationTypes.SET_DIRECTOR_RULES]: (state, payload) => {
    state.directorRules = payload
  },
  [mutationTypes.SET_DIRECTOR_RULE]: (state, payload) => {
    state.directorRule = payload
  },
  [mutationTypes.SET_RULE]: (state, payload) => {
    state.directorRule = {
      ...state.directorRule,
      rules: {
        ...state.directorRule.rules,
        [payload.type]: payload,
      },
    }
  },
  [mutationTypes.SET_RULES_METADATA]: (
    state,
    { ruleType, metadataByEntity }
  ) => {
    state.rulesMetadata = {
      ...state.rulesMetadata,
      [ruleType]: {
        ...state.rulesMetadata[ruleType],
        ...metadataByEntity,
      },
    }
  },
  [mutationTypes.SET_ENTITIES](state, data) {
    state.entities = data
  },
  [mutationTypes.SET_SEGMENTATION_REPORTS](state, data) {
    state.segmentationReports = data
  },
  [mutationTypes.SET_CONVERT_LEAD_STATUSES](state, data) {
    state.convertLeadStatuses = data
  },
  [mutationTypes.SET_OPERATORS](state, data) {
    state.operators = data
  },
}
